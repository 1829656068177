interface scopeProps{
  title: string
  apiName: string, 
  onClick: (e:any)=>void
}

function LoadScopeOptions(props: scopeProps){
  return (
    <div  className="mb-2">
      <div>{props.title}</div>
      <div className="hidden xl:flex flex-row justify-start items-baseline mx-2 mt-1 mb-2">
      <div className="pl-4 pr-24">
        <input id= {props.apiName+"-read"} className="chk-access" name={props.apiName+"-read"} type="checkbox" value="read" onClick={props.onClick}/>
        <label className="pl-1" htmlFor={props.apiName+"-read"}>Read</label>
      </div>
      <div className="pl-4 pr-24">
        <input id={props.apiName+"-write"} className="chk-access" name={props.apiName+"-write"} type="checkbox" value="write" onClick={props.onClick}/>
        <label className="pl-1" htmlFor={props.apiName+"-write"}>Write</label>
      </div>
      <div className="pl-4 pr-24">
        <input id={props.apiName+"-manage"} className="chk-access" name={props.apiName+"-manage"} type="checkbox" value="manage" onClick={props.onClick}/>
        <label className="pl-1" htmlFor={props.apiName+"-manage"}>Manage</label>
      </div>
      </div>
    </div>
  )
}

export default LoadScopeOptions