import { useState, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import AuthContext from '../auth';

function App() {
  var auth = useContext(AuthContext);
  var [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function doLogin() {
    toggleMenu();
    auth.login();
  }

  return (
    <div className="h-full flex flex-row" style={{ width: menuOpen ? '125%' : '100%' }}>
      { menuOpen
        ? <nav className="bg-lilac h-full flex flex-col p-4" style={{ minWidth: '400px' }}>
            <button className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>Close menu</button>
            <Link to="/" className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>Home</Link>
            <Link to="/docs" className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>Documentation</Link>
            <Link to="/contact" className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>Contact</Link>
            <Link to="/faq" className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>FAQ</Link>
            {auth.user
              ? <Link to="/profile" className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={toggleMenu}>{auth.user?.name}</Link>
              : <button className="flex-0 text-center py-6 text-white hover:bg-lilac-dark" onClick={doLogin}>Log in</button>}
          </nav>
        : null}
      <div className="bg-green p-6 h-full w-full">
        <div className="bg-white flex flex-col font-sans h-full pb-6">
          <div className="overflow-y-auto">
            <div className="container mx-auto px-8">
              <header className="flex flex-row items-center justify-between py-6 relative">
                <button className="block lg:hidden flex-0" onClick={toggleMenu}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                  </svg>
                </button>
                <div className="flex-0-0 w-4 lg:hidden"></div>
                <img src="logo.png" className="h-24 flex-0" alt="logo" />
                <div className="flex-1-0"></div>
                <nav className="hidden lg:flex text-lg">
                  <Link to="/" className="text-navy hover:bg-green rounded-full py-3 px-6">Home</Link>
                  <Link to="/docs" className="text-navy hover:bg-green rounded-full py-3 px-6">Documentation</Link>
                  <Link to="/contact" className="text-navy hover:bg-green rounded-full py-3 px-6">Contact</Link>
                  <Link to="/faq" className="text-navy hover:bg-green rounded-full py-3 px-6">FAQ</Link>

                  {auth.user
                    ?  <Link to="/profile" className="bg-green hover:bg-green-dark rounded-full uppercase text-navy py-3 px-6">{auth.user?.name}</Link>
                    : <button className="bg-green hover:bg-green-dark rounded-full uppercase text-navy py-3 px-6" onClick={() => auth.login()}>Log in</button>}
                </nav>
              </header>
              <main><Outlet /></main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
