import { Link } from 'react-router-dom';

function Landing() {
  return (
    <div>
      <div className="lg:flex flex-col-reverse sm:flex-row jusitfy-between items-center py-8">
        <div className="md:w-4/5 lg:w-2/5 flex flex-col items-center sm:items-start text-center sm:text-left">
          <div className="flex flex-row items-center">
            <img className="hidden lg:hidden 2xl:block w-1/4 flex-1 py-4 pr-4" src="arrows.png" alt="" />
            <div className="flex-1">
              <h1 className="uppercase text-6xl text-navy font-bold leading-none tracking-wide mb-2">Financial Software</h1>
              <h2 className="uppercase text-4xl text-navy text-secondary tracking-widest">API Platform</h2>
            </div>
          </div>
          <p className="leading-relaxed mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing. Vestibulum rutrum metus at enim congue scelerisque. Sed suscipit metu non iaculis semper consectetur adipiscing elit.</p>
          <Link to="/start" className="bg-green hover:bg-green-dark py-3 px-6 uppercase text-lg text-navy font-bold rounded-full">Get started</Link>
        </div>
        <div className="block mt-8 lg:pl-16">
          <img src="hero.png" alt="people in an office, working on computers and a whiteboard" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row jusitfy-between lg:py-8">
        <div className="md:w-2/5 flex flex-col text-center md:text-left pr-4 pb-4 lg:pb-0">
          <img src="img1.png" alt="computer" />
          <h3 className="text-xl text-navy font-bold mt-2 mb-2">Labore similique ducimus similique libero</h3>
          <p className="mb-2">Deserunt voluptatem enim harum sit est ut. Voluptatibus architecto mollitia voluptatum fuga. In perferendis praesentium ut dolorem est. Molestiae et praesentium a perferendis corrupti provident aliquid. Quod ipsum debitis architecto aut quisquam veniam et amet.</p>
          <p>Molestiae tempora nam accusantium. Similique autem repellat quidem et quo. Voluptas natus perferendis dolorem itaque. Totam eius quia quia eum officiis.</p>
        </div>
        <div className="md:w-2/5 flex flex-col text-center md:text-left px-2 pb-4 lg:pb-0">
          <img src="img2.png" alt="headphones" />
          <h3 className="text-xl text-navy font-bold mt-2 mb-2">Sit id quia consequatur impedit dolore quidem a ad</h3>
          <p className="mb-2">Voluptatem dolorem aperiam voluptatibus. Ea optio voluptas animi nulla. Quam deserunt eveniet sed voluptatem laudantium.</p>
          <p>Tempora animi ea laboriosam quas. Dolorem tenetur ut in qui quibusdam odio enim. Fugit quia facilis sed sed reiciendis. Officia qui at qui labore vel impedit et et. Consequatur natus ad optio. Ratione odio voluptatem libero velit molestiae.</p>
        </div>
        <div className="md:w-2/5 flex flex-col text-center md:text-left pl-4">
          <img src="img3.png" alt="magifying glass" />
          <h3 className="text-xl text-navy font-bold mt-2 mb-2">Vero in eos molestias</h3>
          <p className="mb-2">Qui eius qui asperiores non doloribus beatae sapiente nemo. Tempora accusantium ea tempore praesentium amet in. Quidem sed placeat ab necessitatibus.</p>
          <p>Nam impedit voluptas est ipsa reprehenderit aperiam harum laborum. Voluptas asperiores sit corrupti quam voluptatem quod modi fugit. Odit et fugiat velit quo. Nisi velit dolorum adipisci aut.</p>
        </div>
      </div>
    </div>
  );
}

export default Landing;