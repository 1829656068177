import { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import AuthContext from '../auth';
import CreateNewToken from './createNewToken';

interface ApiKey {
  jti: string,
  name: string,
  created: moment.Moment,
  expiry: moment.Moment,
  key?: string
}

interface ApiScope {
  name: string,
  scopes: string[]
}

function Profile() {
  const serviceUrl = window.location.origin + "/useradmin";

  const auth = useContext(AuthContext);
  const [keys, setKeys] = useState([] as ApiKey[]);
  const [addingKey, setAddingKey] = useState(false);
  const [newKey, setNewKey] = useState(null as ApiKey | null);
  const [newKeyInput, setNewKeyInput] = useState({ name: "", expiry: 0, scopes: [] as ApiScope[], assignFullAccess: false });

  const fullAccess = [{"name":"valuation","scopes":["read","write","manage"]},
    {"name":"productfunddata","scopes":["read","write","manage"]},
    {"name":"comparator","scopes":["read","write","manage"]},
    {"name":"modeller","scopes":["read","write","manage"]},
    {"name":"idr","scopes":["read","write","manage"]},
    {"name":"incomeanalysis","scopes":["read","write","manage"]},
    {"name":"mifid","scopes":["read","write","manage"]},
    {"name":"orgadmin","scopes":["read","write","manage"]},
    {"name":"fusionintegration","scopes":["read","write","manage"]}]

  useEffect(() =>
  {
    auth.doAuthenticated(async token =>
    {
      const response = await fetch(serviceUrl + '/listKeys',
      {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const body = await response.text();
        alert('Error loading keys! ' + body);
        return;
      }

      const body = await response.json();
      const keysList = body.map((x:any) =>
      {
        return {
          jti: x.jti,
          name: x.name,
          created: moment(x.created),
          expiry: moment(x.expiry)
        };
      });
      setKeys(keysList);
    });
  }, [serviceUrl, auth]);

  function nameChanged(e:React.ChangeEvent<HTMLInputElement>) {
    setNewKeyInput({ ...newKeyInput, name: e.target.value });
  }

  function expiryChanged(e:React.ChangeEvent<HTMLInputElement>) {
    setNewKeyInput({ ...newKeyInput, expiry: parseInt(e.target.value, 10) });
  }

  function scopeChanged(e:React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    var api = target.name.split("-")[0];
    var value = target.value;

    var filteredScopes = newKeyInput.scopes.filter(s => s.name !== api);
    var selectedScope = newKeyInput.scopes.find(s => s.name === api);

    var updatedScopes = target.checked
      ? {name : api, scopes : selectedScope ? selectedScope.scopes.concat(value) : [value]}
      : {name : api, scopes : selectedScope ? selectedScope.scopes.filter(v => v !== value) : []} 

    var scopes = filteredScopes.length > 0 ? filteredScopes.concat(updatedScopes) : [updatedScopes];
    if(!target.checked && updatedScopes.scopes.length === 0)
    {
      scopes = filteredScopes.length > 0 ? filteredScopes : []
    }
    if(!target.checked)
    {
      (document.getElementById(target.id) as HTMLInputElement).checked = false;
    }

    if((Array.from<Element>(document.getElementsByClassName("chk-access")) as HTMLInputElement[]).findIndex(a =>a.checked === false) > -1){
      (document.getElementById("custom-defined") as HTMLInputElement).checked = true;
      setNewKeyInput({ ...newKeyInput, scopes: scopes, assignFullAccess: false});
    }
    else{
      (document.getElementById("full-access") as HTMLInputElement).checked = true;
      setNewKeyInput({ ...newKeyInput, scopes: scopes, assignFullAccess: true});

    }
  }

  function accessChanged(evnt:any){
    //Full access
    if(evnt.target.value === '0'){
      Array.from(document.getElementsByClassName("chk-access")).forEach(
        (element, index, array) => {
          (document.getElementById(element.id) as HTMLInputElement).checked = true;
        });

        setNewKeyInput({ ...newKeyInput, assignFullAccess: true, scopes: fullAccess });
    }
    //Custom defined access
    else{
      setNewKeyInput({ ...newKeyInput, assignFullAccess: false });
    }
  }

  function cancelInput() {
    setNewKeyInput({ name: "", expiry: 0, scopes:[], assignFullAccess: false });
    setAddingKey(false);
  }

  function createKey(evt:any) {
    evt.preventDefault();

    let expiry = moment();

    if (newKeyInput?.expiry === 0) {
      expiry = expiry.add(30, 'days');
    } else if (newKeyInput?.expiry === 1) {
      expiry = expiry.add(60, 'days');
    } else if (newKeyInput?.expiry === 2) {
      expiry = expiry.add(90, 'days');
    } else if (newKeyInput?.expiry === 3) {
      expiry = expiry.add(180, 'days');
    } else if (newKeyInput?.expiry === 4) {
      expiry = expiry.add(1, 'years');
    }

    auth.doAuthenticated(async token =>
    {
      const response = await fetch(serviceUrl + '/createKey', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ 
          name: newKeyInput.name, 
          expiry: expiry.toISOString(), 
          scopes: Object.assign({}, ...(newKeyInput.scopes.map((s) => ({ [s.name]: s.scopes })))),
          assignFullAccess: newKeyInput.assignFullAccess
        }),
      });

      if (!response.ok) {
        const body = await response.text();
        alert('Error getting key! ' + body);
        return;
      }

      const body = await response.json();

      setNewKey({
        jti: body.jti,
        name: newKeyInput.name,
        created: moment(body.created),
        expiry: expiry,
        key: body.apiKey
      });
      setNewKeyInput({ name: "", expiry: 0, scopes: [], assignFullAccess: false });
      setAddingKey(false);
    });
  }

  function confirmKeyAdded() {
    if (newKey) keys.push({ ...newKey });
    setKeys(keys);
    setNewKey(null);
  }

  function revokeKey(jti:string) {
    var key = keys.find(k => k.jti === jti);
    if (!key) return;

    auth.doAuthenticated(async token =>
    {
      const response = await fetch(serviceUrl + '/revokeKey', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ jti: key!.jti, expiry: key!.expiry.toISOString() }),
      });

      if (!response.ok) {
        const body = await response.text();
        alert('Error revoking key! ' + body);
        return;
      }

      setKeys(keys.filter(k => k.jti !== jti));
    });
  }

  return (
    <div className="py-12">
      <h1 className="text-6xl text-navy font-bold leading-none tracking-wide mb-16">Hello, {auth.user?.name}!</h1>

      <h2 className="text-4xl text-navy leading-none tracking-wide mb-8">API Keys</h2>

      <p>
        These keys can be used to authenticate with our API platform, they should be passed as a bearer token; the HTTP Authorization header should
        contain the word 'Bearer' followed by your token. For example:
        <code className="block bg-grey p-4 mt-2 break-all">
          Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjMmEzODY2ZS0xZWQ0LTQ5ZDAtYmY3Zi0yZGI3YTBiMjg4ZTAiLCJqd...
        </code>
      </p>
    
      <p className="pt-2 pb-4 font-bold">Warning: An API key grants access to all functions of the API platform in the context of your user, so should be treated as securely as a password.</p>

      { addingKey
        ? <CreateNewToken nameChanged= {nameChanged} 
          expiryChanged= {expiryChanged} 
          scopeChanged= {scopeChanged} 
          accessChanged= {accessChanged}
          handleSubmit= {createKey}
          cancelInput= {cancelInput}/>
        : <button className="mt-2 mb-8 bg-green hover:bg-green-dark rounded-full text-navy px-6 py-3" 
            onClick={() => setAddingKey(true)}>Create a new key</button> 
      }

      { newKey &&
        <div>
          <div className="hidden xl:block bg-lilac rounded-full px-8 py-2 mx-8 mt-2 mb-8">
            <span>Your new API key has been created. Make sure you copy it now because it will not be accessible later:</span>
            <div className="flex flex-row justify-start items-baseline pt-2">
              <span>Name: {newKey.name}</span>
              <span className="pl-4">Expires: {newKey.expiry.toISOString()}</span>
              <span className="pl-4">Data: </span>
              <input className="flex-1-0 ml-2 rounded-full px-4 py-2" type="text" value={newKey.key} disabled></input>
              <button className="bg-green hover:bg-green-dark rounded-full text-navy px-6 py-3 ml-4" onClick={confirmKeyAdded}>OK</button>
            </div>
          </div>
          <div className="block xl:hidden bg-lilac p-4">
            <span>Your new API key has been created. Make sure you copy it now because it will not be accessible later:</span>
            <div className="flex flex-col justify-start items-baseline pt-2">
              <span>Name: {newKey.name}</span>
              <span className="pt-2">Expires: {newKey.expiry.toISOString()}</span>
              <div className="flex flex-row items-baseline pt-2 w-full">
                <span className="pr-2">Data: </span>
                <input className="p-2 flex-1-0" type="text" value={newKey.key} disabled></input>
              </div>
              <button className="bg-green hover:bg-green-dark rounded-full text-navy px-6 py-3 mt-4" onClick={confirmKeyAdded}>OK</button>
            </div>
          </div>
        </div> }

      {!addingKey && 
        <table className="w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Expires</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { keys.map(k => (
            <tr>
              <td className="text-center">{k.name}</td>
              <td className="text-center">
                <span className="hidden lg:block">{k.created.toISOString()}</span>
                <span className="block lg:hidden">{k.created.format('YYYY-MM-DD')}</span>
              </td>
              <td className="text-center">
                <span className="hidden lg:block">{k.expiry.toISOString()}</span>
                <span className="block lg:hidden">{k.expiry.format('YYYY-MM-DD')}</span>
              </td>
              <td className="mt-1 mb-1 flex flex-row justify-center">
                <button className="flex-none bg-green hover:bg-green-dark text-navy rounded-full px-4 py-1" onClick={() => revokeKey(k.jti)}>Revoke</button>
              </td>
            </tr>
          )) }
        </tbody>
        </table>
      }
    </div>
  );
}

export default Profile;