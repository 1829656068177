import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as Msal from '@azure/msal-browser';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './layout/app';
import Landing from './views/landing';
import Profile from './views/profile';
import reportWebVitals from './reportWebVitals';
import AuthContext, { scopes } from './auth';

const config = {
  auth: {
    clientId: 'c0f881c0-02e8-4d3a-9fb9-22ccf6e5e808',
    authority: 'https://synaptic.b2clogin.com/synaptic.onmicrosoft.com/B2C_1_Signin_Only',
    knownAuthorities: ['synaptic.b2clogin.com'],
    redirectUri: window.location.origin
  }
};

const msal = new Msal.PublicClientApplication(config);
let acc = msal.getActiveAccount();
if (acc === null)
{
  const accs = msal.getAllAccounts();
  if (accs.length >= 1)
  {
    acc = accs[0];
    msal.setActiveAccount(acc);
  }
}

function Root() {
  const [ctx, setCtx] = useState({
    login,
    doAuthenticated,
    user: acc
  });

  useEffect(() =>
  {
    msal.handleRedirectPromise().then(resp =>
    {
      if (resp !== null)
      {
        msal.setActiveAccount(resp.account);
        setCtx(c => { return { ...c, user: resp.account}});
      }
    });
  }, []);

  function login() {
    msal.loginRedirect({ scopes: [scopes.access] });
  }

  function doAuthenticated<T>(f: (token:string) => Promise<T>) {
    msal.acquireTokenSilent({ scopes: [scopes.access] })
        .then(resp => f(resp.accessToken),
              err => { console.log('Failed to get token: ' + err); });
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider value={ctx}>
      <Routes>
        <Route element={<App/>}>
          <Route path="/" element={<Landing/>} />
          <Route path="/profile" element={<Profile/>} />
        </Route>
      </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode><Root /></React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
