import LoadScopeOptions from "./scope"

interface createNewTokenProps{
  nameChanged: (e:any)=>void, 
  expiryChanged: (e:any)=>void,  
  scopeChanged: (e:any)=>void,
  accessChanged: (e:any)=>void
  handleSubmit: (e:any)=>void,
  cancelInput: ()=>void
}

function CreateNewToken(props:createNewTokenProps) {

  return (
    <div>
      <form onSubmit={props.handleSubmit}>
        <div className="rounded-full mt-2 mb-2">

          <div className="mt-2 mb-1">
            <label htmlFor="name" className="pr-4">Name</label>
          </div>
          <div className="mt-2 mb-2">
            <input id="name" name="name" type="text" required className="border mw-100 rounded pl-2 w-full md:w-1/2" onChange={props.nameChanged} />
          </div>

          <div className="mt-2 mb-1">
            <label htmlFor="expiry">Expires in</label>
          </div>
          <div className="mt-2 mb-2">

            <div className="inline-block relative w-64">
              <select onChange={props.expiryChanged}  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
              <option value="0">30 days</option>
                <option value="1">60 days</option>
                <option value="2">90 days</option>
                <option value="3">180 days</option>
                <option value="4">1 year</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>

          </div>

          <div className="py-2 mt-2 mb-2">
            <div>Scopes</div>
            <div>Authorize the access of scope associted with the token</div>
            <div>Scopes
              <div className="pl-4 pr-24">
                <input id="full-access" name="acess-scope" type="radio" value="0" onClick={props.accessChanged} />
                <label className="pl-1" htmlFor="full-access">full access</label>
              </div>
              <div className="pl-4 pr-24">
                <input id="custom-defined" name="acess-scope" type="radio" defaultChecked={true} value="1" onClick={props.accessChanged} />
                <label className="pl-1" htmlFor="custom-defined">custom defined</label>
              </div>
            </div>
          </div>

          <div className="py-2 mt-2 mb-2">

            <LoadScopeOptions title= "Valuation" apiName="valuation" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Product and Funds" apiName="productfunddata" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Comparator" apiName="comparator" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Modeller" apiName="modeller" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Idr" apiName="idr" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Income Analysis" apiName="incomeanalysis" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Organisation Administration" apiName="orgadmin" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "MiFID" apiName="mifid" onClick={props.scopeChanged}/>
            <LoadScopeOptions title= "Fusion Integration" apiName="fusionintegration" onClick={props.scopeChanged}/>

            <div  className="mb-2 mt-10">
              <button className="bg-green hover:bg-green-dark rounded-full text-navy px-6 py-3 mr-4" type="button" onClick={props.cancelInput}>Cancel</button>
              <button className="bg-green hover:bg-green-dark rounded-full text-navy px-6 py-3" type="submit">Create</button>
            </div>

          </div>
        </div>
      </form>
   </div>
  );
}

export default CreateNewToken;