import React from 'react';
import * as Msal from '@azure/msal-browser';

export const scopes = {
  access: 'https://synaptic.onmicrosoft.com/c0f881c0-02e8-4d3a-9fb9-22ccf6e5e808/access'
};

export default React.createContext({
  login: () => {},
  doAuthenticated<T>(f: (token:string) => Promise<T>) { },
  user: null as Msal.AccountInfo | null
});